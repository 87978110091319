.PersonContact {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px;
    background-color: rgb(47, 61, 57);
    border-radius: 10px;
    margin-top: 15px;
    -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
    box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}

.contact__label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}