.Person {
    width: 50%;
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 15px;
    background-color: rgb(44, 73, 64);
    border-radius: 10px;
    margin-top: 15px;
    -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
    box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}

.collapsed {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .Person {
        width: 90%;
    }
}